import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentWrapper/ContentWrapper'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactStars from "react-rating-stars-component";
import { AiFillStar, AiOutlineShoppingCart } from 'react-icons/ai'
import { BsStarHalf } from 'react-icons/bs'
import { FaRegUser } from 'react-icons/fa'
import { getMultitpleDataApi, getProductDetailApi } from '../utils/api'
import { numberFormat } from '../components/numberFormate'
import { useCart } from 'react-use-cart'
import TagManager from 'react-gtm-module'
import ayush from "../assets/ayush.webp"
import gmp from "../assets/gmp.webp"
import iso from "../assets/iso.webp"
import madeinindia from "../assets/madeinindia.webp"
import { HiOutlinePhoneMissedCall } from 'react-icons/hi'

const NewLandingPage = () => {

    const { slug } = useParams();
    const pathname = window.location.href;
    const userid = JSON.parse(localStorage.getItem('userdata'))?.id;
    const [productd, setProductd] = useState([]);
    const productid = productd?.id;
    localStorage.setItem("lproductid", productid);
    const [mimage, setMImage] = useState([]);
    const { addItem } = useCart();
    const navigate = useNavigate();

    const BASE_URL = "https://backendsatkartar.satkartar.co.in/assets/img/product/";
    const BASE_URL_BENEFIT = "https://backendsatkartar.satkartar.co.in/assets/img/benefits/";
    const BASE_URL_ING = "https://backendsatkartar.satkartar.co.in/assets/img/ingredient/";
    const BASE_URL_HOW = "https://backendsatkartar.satkartar.co.in/assets/img/how/";
    const BASE_URL_OFFER = "https://backendsatkartar.satkartar.co.in/assets/img/offer/";
    const BASE_URL_USE = "https://backendsatkartar.satkartar.co.in/assets/img/howtouse/";

    const [pbenefittitle, setPBenefitTitle] = useState([]);
    const [pbenefit, setPBenefit] = useState([]);

    const [keybenefittitle, setKeyBenefitTitle] = useState([]);
    const [keybenefit, setKeyBenefit] = useState([]);
    const [video, setVideo] = useState([]);
    const [ingredient, setIngredient] = useState([]);
    const [ingredienttitle, setIngredientTitle] = useState([]);
    const [how, setHow] = useState([]);
    const [offer, setOffer] = useState([]);
    const [overview, setOverview] = useState([]);
    const [resulttitle, setResultTitle] = useState([]);
    const [result, setResult] = useState([]);
    const [reviewtitle, setReviewTtitle] = useState([]);
    const [review, setReview] = useState([]);
    const [faqtitle, setFAQTitle] = useState([]);
    const [faq, setFAQ] = useState([]);


    const buyItNow = (productd) => {
        addItem(productd);
        TagManager.dataLayer({
            dataLayer: {
                event: "begin_checkout",
                pagePath: pathname,
                pageTitle: productd?.meta_title,
                eventModel: {
                    ecomm_totalvalue: productd.price,
                    ecomm_pagetype: "Cart",
                    value: productd.price,
                    currency: "INR",
                    items: [
                        {
                            id: productd.id,
                            name: productd.name,
                            brand: "Satkartar",
                            price: productd.price,
                            quantity: 1,
                        }
                    ]
                }
            },
        });
        if (userid) {
            navigate("/delivery-address");
        }
        else {
            navigate("/checkout-login");
        }
    }

    useEffect(() => {
        getLandingProductDetail(slug);
        if (productid) {
            getProductMoreImage(productid);
            getLandingProductBenefits(productid);
            getLandingProductBenefitsTitle(productid);
            getLandingKeyBenefitTitle(productid);
            getLandingKeyBenefits(productid);
            getLandingProductVideo(productid);
            getLandingKeyIngredientTitle(productid);
            getLandingKeyIngredient(productid);
            getLandingHowToUse(productid);
            getLandingProductOffer(productid);
            getLandingProductOverview(productid);
            getLandingProductResult(productid);
            getLandingProductReviewTitle(productid);
            getLandingProductReview(productid);
            getLandingProductFAQTitle(productid);
            getLandingProductFAQ(productid);
        }
    }, [slug, productid])

    const getLandingProductDetail = (slug) => {
        getProductDetailApi(`get-new-landing-page-product-details/${slug}`).then((res) => {
            if (res?.data.status === 1) {
                setProductd(...[res?.data.product]);
            }
            else {
                window.open('/page-not-found', "_self");
            }
        })
            .catch((error) => {
                window.open('/page-not-found', "_self");
            });
    }
    const getProductMoreImage = (productid) => {
        getMultitpleDataApi(`get-landing-page-multiple-image-data/${productid}`).then((res) => {
            setMImage(...[res.data?.pimage]);
        })
    }
    const getLandingProductBenefitsTitle = (productid) => {
        getMultitpleDataApi(`get-landing-page-product-benefit-title/${productid}`).then((res) => {
            setPBenefitTitle(...[res.data?.pbenefitt]);
        })
    }
    const getLandingProductBenefits = (productid) => {
        getMultitpleDataApi(`get-landing-page-product-benefit-data/${productid}`).then((res) => {
            setPBenefit(...[res.data?.pbenefit]);
        })
    }
    const getLandingKeyBenefitTitle = (productid) => {
        getMultitpleDataApi(`get-landing-page-benefits-title/${productid}`).then((res) => {
            setKeyBenefitTitle(...[res.data?.data]);
        })
    }
    const getLandingKeyBenefits = (productid) => {
        getMultitpleDataApi(`get-landing-page-benefits/${productid}`).then((res) => {
            setKeyBenefit(...[res.data?.benefit]);
        })
    }
    const getLandingProductVideo = (productid) => {
        getMultitpleDataApi(`get-landing-page-product-video-data/${productid}`).then((res) => {
            setVideo(...[res.data?.pvideo]);
        })
    }
    const getLandingKeyIngredientTitle = (productid) => {
        getMultitpleDataApi(`get-landing-page-ingredient-title/${productid}`).then((res) => {
            setIngredientTitle(...[res.data?.ingredienttitle]);
        })
    }
    const getLandingKeyIngredient = (productid) => {
        getMultitpleDataApi(`get-landing-page-ingredient/${productid}`).then((res) => {
            setIngredient(...[res.data?.ingredient]);
        })
    }
    const getLandingHowToUse = (productid) => {
        getMultitpleDataApi(`get-new-landing-how-to-use-data/${productid}`).then((res) => {
            setHow(...[res.data?.how]);
        })
    }
    const getLandingProductOffer = (productid) => {
        getMultitpleDataApi(`get-new-landing-product-offer-data/${productid}`).then((res) => {
            setOffer(...[res.data?.offer]);
        })
    }
    const getLandingProductOverview = (productid) => {
        getMultitpleDataApi(`get-new-landing-product-overview-data/${productid}`).then((res) => {
            setOverview(...[res.data?.overview]);
        })
    }
    const getLandingProductResult = (productid) => {
        getMultitpleDataApi(`get-new-landing-result-data/${productid}`).then((res) => {
            setResult(...[res.data?.result]);
            setResultTitle(...[res.data?.howtousetitle]);
        })
    }
    const getLandingProductReviewTitle = (productid) => {
        getMultitpleDataApi(`get-landing-page-review-title/${productid}`).then((res) => {
            setReviewTtitle(...[res.data?.reviewtitle]);
        })
    }
    const getLandingProductReview = (productid) => {
        getMultitpleDataApi(`get-landing-page-review/${productid}`).then((res) => {
            setReview(...[res.data?.review]);
        })
    }
    const getLandingProductFAQTitle = (productid) => {
        getMultitpleDataApi(`get-landing-page-faq-title/${productid}`).then((res) => {
            setFAQTitle(...[res.data?.faqtitle]);
        })
    }
    const getLandingProductFAQ = (productid) => {
        getMultitpleDataApi(`get-landing-page-faq/${productid}`).then((res) => {
            setFAQ(...[res.data?.faq]);
        })
    }

    let Schemadata = {
        "breadcrum": {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://www.satkartar.co.in/"
            }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Products",
                "item": "https://www.satkartar.co.in/products"
            }, {
                "@type": "ListItem",
                "position": 3,
                "name": `${productd?.name}`
            }]
        },
        "@context": "https://schema.org/",
        "@type": "Product",
        "sku": `${productd?.product_sku}`,
        "image": `${BASE_URL + productd?.image}`,
        "name": `${productd?.name}`,
        "description": `${productd?.short_desc}`,
        "brand": {
            "@type": "Brand",
            "name": "Satkartar"
        },
        "offers": {
            "@type": "Offer",
            "url": `${pathname}`,
            "itemCondition": "NewCondition",
            "availability": "InStock",
            "price": `${productd?.price}.00`,
            "priceCurrency": "INR",
            "priceValidUntil": "2030-11-20",
            "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingRate": {
                    "@type": "MonetaryAmount",
                    "value": 0.00,
                    "currency": "INR"
                },
                "shippingDestination": {
                    "@type": "DefinedRegion",
                    "addressCountry": "IND"
                },
                "deliveryTime": {
                    "@type": "ShippingDeliveryTime",
                    "handlingTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 0,
                        "maxValue": 100,
                        "unitCode": "DAY"
                    },
                    "transitTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 1,
                        "maxValue": 100,
                        "unitCode": "DAY"
                    }
                }
            }
        },
        "review": [
            review?.map((review) => (
                {
                    "@type": "Review",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": `${review?.reviews_rating}`,
                        "bestRating": 5
                    },
                    "name": `${review?.reviews_desc}`,
                    "author": {
                        "@type": "Person",
                        "name": `${review?.reviews_name}`
                    },
                    "publisher": {
                        "@type": "Organization",
                        "name": "Satkartar Shopping LTD."
                    }
                }
            )),
        ],
        "faqs": {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                faq?.map((faq) => (
                    {
                        "@type": "Question",
                        "name": `${faq?.faq_title}`,
                        "acceptedAnswer": [
                            {
                                "@type": "Answer",
                                "text": `${faq?.faq_desc}`
                            }
                        ]
                    }
                )),
            ]
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": `${productd?.product_rating}`,
            "reviewCount": `${productd?.price}`
        }
    }

    return (
        <div className="newlp bg-white">
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(Schemadata) }}
            />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{productd?.meta_title}</title>
                <link rel="canonical" href={pathname} />
                {productd?.no_follow_index_status === 1 ?
                    <meta name="robots" content="noindex,nofollow" />
                    :
                    ""
                }
                <meta name="description" content={productd?.meta_description} />
                <meta name="keywords" content={productd?.meta_keyword} />
                <meta property="og:description" content={productd?.meta_description} />
                <link rel="preload" fetchpriority="high" as="image" href={BASE_URL + productd?.image} type="image/webp"></link>
                {/* <script
                    type="text/javascript"
                    src="https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
                    id="aisensy-wa-widget"
                    widget-id="Z6cuIG"
                >
                </script> */}
            </Helmet>

            <div className="col-lg-12 mt-4">
                <ContentWrapper>
                    <div className="row">
                        <div className="col-md-5 col-xl-6">
                            <Carousel className="lpcarousel" autoPlay={true} infiniteLoop={true} showIndicators={false} swipeable={false} showStatus={false} showThumbs={true}>
                                <div>
                                    <img rel="preload" fetchpriority="high" as="image" alt={productd?.product_alt_name} src={BASE_URL + productd?.image} />
                                </div>
                                {mimage.length > 0 ? mimage?.map((mimage, index) => (
                                    <div key={index}>
                                        <img rel="preload" fetchpriority="high" as="image" alt={mimage?.product_image} src={BASE_URL + mimage?.product_image} />
                                    </div>
                                ))
                                    :
                                    <div>
                                        <img rel="preload" fetchpriority="high" as="image" alt={productd?.product_alt_name} src={BASE_URL + productd?.image} />
                                    </div>
                                }
                            </Carousel>
                        </div>
                        <div className="col-md-7 col-xl-6 newlp">
                            <div className="product-detail-info">
                                <div className="ps-lg-4 mt-4 mt-md-0">
                                    <h1 className="mb-1 product__title">{productd?.name}</h1>
                                    <div className="d-flex align-items-baseline">
                                        <small className="text-warning">
                                            <AiFillStar />
                                            <AiFillStar />
                                            <AiFillStar />
                                            <AiFillStar />
                                            <BsStarHalf />
                                        </small>
                                        <p className="m-0 p-0 lprating">
                                            <span className="fs-5 ms-2">{Math.floor(Math.random() * 5000)} ratings </span>
                                        </p>
                                    </div>
                                    <p className="lpbenefits">
                                        <span className="benefits_span text-dark">{productd?.product_tag_line}</span>
                                    </p>

                                    <p className="product__text_description col-lg-10 col-12">{productd?.short_desc}</p>
                                    <hr className="lphr" />
                                    <div className="benefits__text mt-2">
                                        <h4>{pbenefittitle?.lp_heading}</h4>
                                    </div>
                                    <div className="wn-flex description_icons col-lg-10 col-12">
                                        {pbenefit?.map((pbenefit, index) => (
                                            <div className="desc_icon_box" key={index}>
                                                <img src={BASE_URL_BENEFIT + pbenefit?.lp_image} />
                                                <span>{pbenefit?.lp_title}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        <div className="fs-5 mt-4 lpprice">
                                            <span className="fw-bold text-dark">{numberFormat(productd?.price)}</span>
                                            <span className="fs-5 text-decoration-line-through text-muted">{numberFormat(productd?.product_cut_price)}</span>
                                            <span><small className="ms-2 badge">{productd?.product_save}</small></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="productBtn landingBtn">
                                            <button className="buyItNow" onClick={() => buyItNow(productd)}>Buy It Now</button>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div className="bottom-block__text">
                                            <div className="main-product__block main-product__block-custom_liquid">
                                                <div className="bottom-block__text">
                                                    <div className="row sf-custom__grid flex flex-wrap">
                                                        <div className="col-lg-3 col-3 items-center service-icons">
                                                            <img src="https://cdn.shopify.com/s/files/1/0791/3934/0582/files/free_expert_consultation.png?v=1710239258" alt="expert consultation" />
                                                            <p>Free Expert Consultation</p>
                                                        </div>
                                                        <div className="col-lg-3 col-3 items-center service-icons">
                                                            <img src="https://cdn.shopify.com/s/files/1/0791/3934/0582/files/Free_Shipping.png?v=1710239258" alt="Free Shipping" />
                                                            <p>Free Shipping on Prepaid</p>
                                                        </div>
                                                        <div className="col-lg-3 col-3 items-center service-icons">
                                                            <img src="https://cdn.shopify.com/s/files/1/0791/3934/0582/files/100_Ayurvedic.png?v=1710239259" />
                                                            <p>100% Ayurvedic</p>
                                                        </div>
                                                        <div className="col-lg-3 col-3 items-center service-icons">
                                                            <img src="https://cdn.shopify.com/s/files/1/0791/3934/0582/files/cash_on_delivery.png?v=1710239259" alt="cash on delivery" />
                                                            <p>Cash on <br />Delivery </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-product__block main-product__block-custom_liquid">
                                                <div className="bottom-block__text">
                                                    <p className="lock_para m-0 guaranteed">
                                                        <img src="https://cdn.shopify.com/s/files/1/0588/0591/1689/files/lock.png?v=1677565286" className="lock_img" /><span>GUARANTEED SAFE CHECKOUT<span></span></span>
                                                    </p>
                                                    <img style={{ width: "100%" }} src="https://cdn.shopify.com/s/files/1/0602/2440/5694/files/new-payment-svg.svg?v=1642654145" alt="Trusted payment options" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <section className="section-tab bg-tab" id="navbar">
                    <ContentWrapper>
                        <div className="all-tab alltabs">
                            <ul id="ulTabs">
                                <li> <a className="" href="#Benefits">Key Benefits</a> </li>
                                <li> <a className="" href="#Ingredients">Key Ingredients </a> </li>
                                <li> <a className="" href="#How-to-Use">How to Use</a> </li>
                                <li> <a className="" href="#Testimonials">Testimonials</a> </li>
                                <li> <a className="" href="#Faqs">FAQs</a> </li>
                            </ul>
                        </div>
                    </ContentWrapper>
                </section>
                {(() => {
                    if (keybenefit) {
                        return (
                            <div id="Benefits">
                                <section className="section-all newlp">
                                    <ContentWrapper>
                                        <div className="t-tittle text-center">
                                            <h2 className="">{keybenefittitle?.benefits_title}</h2>
                                        </div>
                                        <div className="key-benefit">
                                            <div className="row">
                                                {keybenefit?.map((keybenefit, index) => (
                                                    <div className="col-lg-3 col-sm-6 col-6 p-2" key={index}>
                                                        <div className="use-card text-center">
                                                            <img className="" alt={keybenefit?.benefit_image} src={BASE_URL_BENEFIT + keybenefit?.benefit_image} />
                                                            <p>{keybenefit?.benefit_title}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </ContentWrapper>
                                </section>
                            </div>
                        )
                    }
                })()}
                {(() => {
                    if (video) {
                        return (
                            <div id="Video">
                                <section className="video-sec">
                                    <ContentWrapper>
                                        <div className="row align-items-center" style={{ border: "1px solid #000" }}>
                                            <div className="col-md-6 col-lg-6 p-2 videosec">
                                                <iframe src={video?.pvideo_url} title="&#39;World Happiness Day&quot; Celebrated by SK Vision Foundation on- 20.03.2024" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="pro_videoRight">
                                                    <div>
                                                        <div className="title-wrapper title-wrapper--no-top-margin">
                                                            <h2 className="videoTitle ">{video?.pvideo_title}</h2>
                                                        </div>
                                                        <div className="title-wrapper title-wrapper--no-top-margin">
                                                            <p className="videoContnt">{video?.pvideo_desc}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ContentWrapper>
                                </section>
                            </div>
                        )
                    }
                })()}
                {(() => {
                    if (ingredient) {
                        return (
                            <div id="Ingredients">
                                <section className="section-all newlp">
                                    <ContentWrapper>
                                        <div className="t-tittle text-center">
                                            <h2 className="">{ingredienttitle?.ingredient_title}</h2>
                                        </div>
                                        <div className="key-ingredients">
                                            <div className="row justify-content-center">
                                                {ingredient?.map((ingredient, index) => (
                                                    <div className="col-lg-3 col-sm-6 col-6 mb-5 text-center" key={index}>
                                                        <div className="ing-box">
                                                            <img src={BASE_URL_ING + ingredient?.ingredient_image} alt={ingredient?.ingredient_image} />
                                                        </div>
                                                        <div className="ing-box-text">
                                                            <h4 className="my-1">{ingredient?.ingredient_title}</h4>
                                                            <p className="mb-0 small text-left">{ingredient?.ingredient_desc}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </ContentWrapper>
                                </section>
                            </div>
                        )
                    }
                })()}
                {(() => {
                    if (how) {
                        return (
                            <div id="How-to-Use">
                                <section className="steps">
                                    <ContentWrapper>
                                        <div className="t-tittle text-center mb-2">
                                            <h2 className="">{how?.use_title}</h2>
                                        </div>
                                        <div className="row align-items-center pb-md-3">
                                            <div className="col-md-6 col-lg-6 mb-4 mb-md-0">
                                                <img className="rounded-1 w-100" src={BASE_URL_HOW + how?.use_image} alt={how?.use_image} />
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="p-2">
                                                    <div className="step">
                                                        <div dangerouslySetInnerHTML={{ __html: how?.use_desc }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ContentWrapper>
                                </section>
                            </div>
                        )
                    }
                })()}
                {(() => {
                    if (offer) {
                        return (
                            <div id="How-to-Use">
                                <section className="py-2 banner">
                                    <ContentWrapper>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="theme-border-radius red-gradient-bg text-white d-md-flex p-4 p-sm-5 align-items-center d-flex flex-wrap">
                                                    <div className="col-12 col-md-7 order-2 order-md-1 px-lg-5 text-center text-md-start">
                                                        <div dangerouslySetInnerHTML={{ __html: offer?.offer_desc }}></div>
                                                        <div className="mt-4 mt-sm-5">
                                                            <button onClick={() => buyItNow(productd)} className="btn-new btn-primary-new d-block d-sm-inline-block">Order Now</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mx-auto order-1 order-md-2 mb-4 mb-md-0 px-lg-5">
                                                        <picture className="img-fluid">
                                                            <img src={BASE_URL_OFFER + offer?.offer_img} alt={offer?.offer_img} className="img-fluid" />
                                                        </picture>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ContentWrapper>
                                </section>
                            </div>
                        )
                    }
                })()}
                {(() => {
                    if (overview) {
                        return (
                            <div id="Product-Details">
                                <section className="section-product-details section-all lpproductdetails">
                                    <div className="container">
                                        <div className="t-tittle mb-5">
                                            <h2 className="">{overview?.overview_title}</h2>
                                        </div>
                                        <div className="row product-inner-part px-40">
                                            <div className="col-lg-12 proven-part pdetailnew lpproductdetail">
                                                <div dangerouslySetInnerHTML={{ __html: overview?.overview_desc }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                    }
                })()}
                {(() => {
                    if (result) {
                        return (
                            <div id="steps">
                                <section className="section-all">
                                    <div className="container">
                                        <div className="t-tittle text-center mb-5">
                                            <h2 className="">{resulttitle?.howtouse_title}</h2>
                                        </div>
                                        <div className="our-certification">
                                            <div className="row justify-content-center">
                                                <ul className="step step-sm step-icon-lg step-centered mb-10">
                                                    {result?.map((result, index) => (
                                                        <li className="step-item" key={index}>
                                                            <div className="step-content-wrapper align-items-center">
                                                                <span className="step-icon step-icon-border">
                                                                    <span className="svg-icon text-primary">
                                                                        <img src={BASE_URL_USE + result?.howtouse_image} alt="" />
                                                                    </span>
                                                                </span>
                                                                <div className="step-content">
                                                                    <h4 className="step-title">{result?.howtouse_title}</h4>
                                                                    <p>{result?.howtouse_desc}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )
                    }
                })()}
                {(() => {
                    if (review) {
                        return (
                            <div id="Testimonials">
                                <section className="">
                                    <ContentWrapper>
                                        <div className="t-tittle text-center">
                                            <h2 className="">{reviewtitle?.reviews_title}</h2>
                                        </div>
                                        <div className="reviews">
                                            <div className="row">
                                                {review?.map((review, index) => (
                                                    <div className="col-sm-6 col-lg-4 mt-3" key={index}>
                                                        <div className="card card-body border">
                                                            <p className="mb-2">“{review?.reviews_desc}”</p>
                                                            <ul className="list-inline mb-3">
                                                                <ReactStars
                                                                    count={review?.reviews_rating}
                                                                    size={24}
                                                                    isHalf={true}
                                                                    emptyIcon={<i className="far fa-star"></i>}
                                                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                    fullIcon={<i className="fa fa-star"></i>}
                                                                    activeColor="#ffc107"
                                                                    color="#ffc107"
                                                                />
                                                            </ul>
                                                            <div className="d-flex align-items-center">
                                                                <small className="mb-0 heading-color fw-semibold"><FaRegUser />{review?.reviews_name}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </ContentWrapper>
                                </section>
                            </div>
                        )
                    }
                })()}
                <div id="Certification">
                    <section className="section-all">
                        <ContentWrapper>
                            <div className="t-tittle text-center mb-5">
                                <h2 className="">Our Certification</h2>
                            </div>
                            <div className="our-certification">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img src={ayush} alt={ayush} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img src={madeinindia} alt={madeinindia} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img src={gmp} alt={gmp} />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-6 mb-5 text-center">
                                        <div className="our-certi">
                                            <img src={iso} alt={iso} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ContentWrapper>
                    </section>
                </div>
                {(() => {
                    if (faq) {
                        return (
                            <div id="Faqs" className="">
                                <section className="section-all mb-3">
                                    <ContentWrapper>
                                        <div className="qus-bg">
                                            <div className="t-tittle text-center mb-5">
                                                <h2 className="text-white">{faqtitle?.faq_title}</h2>
                                            </div>
                                            <Accordion allowZeroExpanded>
                                                {faq?.map((faq, index) => (
                                                    <AccordionItem className="mt-4" key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                {faq?.faq_title}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <p>{faq?.faq_desc}</p>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        </div>
                                    </ContentWrapper>
                                </section>
                            </div>
                        )
                    }
                })()}
                {(() => {
                    if (productd?.price !== 0) {
                        return (
                            <section className="fact-area mob">
                                <div className="container">
                                    <div className="col-12 col-lg-12 pt-1">
                                        <div className="">
                                            <h4 className="sticky-title">{productd?.page_title}</h4>
                                        </div>
                                    </div>
                                    <div className="row text-center mb-2">
                                        <div className="col-3 col-lg-3 pt-1 d-flex">
                                            <div className="stickyprice">
                                                <span className="sticky-price"><b>{numberFormat(productd?.price)}</b></span>
                                            </div>
                                        </div>
                                        <div className="col-9 col-lg-9 pt-1 d-flex" style={{ justifyContent: "space-between" }}>
                                            {(() => {
                                                if (productd?.display_mobile_status === 1) {
                                                    return (
                                                        <Link className="btncallnow" to={`tel: ${productd?.display_mobile_number}`}><HiOutlinePhoneMissedCall className="userIcons" /> Call Now</Link>
                                                    )
                                                }
                                            })()}
                                            <div className="sticky-buy-now" id="cartsticky">
                                                <span className="">
                                                    <AiOutlineShoppingCart className={productd?.product_slug_url} />
                                                </span>
                                                <button onClick={() => buyItNow(productd)}>Buy Now</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        )
                    }
                })()}
            </div>
        </div >
    )
}

export default NewLandingPage